<template>
  <div class="footer-feedback">
    <div class="footer-feedback__title">{{ $t('ratingProduct.support') }}</div>

    <a class="footer-feedback__link" :href="helpLink">
      {{ $t('otherQuestions') }}
    </a>

    <a
      v-if="showFeedback"
      v-test-id="'footer-feedback-messenger'"
      class="footer-feedback__messenger"
      @click.prevent="showFlomniComponent"
    >
      <message-icon class="footer-feedback__messenger-icon" />

      {{ $t('widgetContactChatButton') }}
    </a>

    <a
      v-if="phoneNumber"
      :href="`tel:${phoneNumber}`"
      class="footer-feedback__link footer-feedback__phone"
    >
      {{ phoneNumberFormatted }}
    </a>

    <a
      v-if="isRuSite"
      v-test-id="'footer-feedback-city'"
      class="footer-feedback__city"
      @click="openCityModal"
    >
      <footer-location />

      {{ globalCity.name }}
    </a>

    <div v-if="feedback.text" class="footer-feedback__link" v-html="feedback.text" />

    <client-only v-if="isRuSite">
      <footer-review-button v-test-id="'footer-feedback-review'" />
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import useSiteInfo from '@/composables/use-site-info';
import { cdekConsoleError } from '@/utils/console-wrapper';
import messageIcon from '@/components/Footer/icons/message.svg';
import useSettings from '@/composables/use-settings';
import type { FooterFeedback } from '#root/src/types/store/footer';
import { useSelectedCityData } from '#root/src/composables/use-selected-city-data';
import useFlomni from '@/composables/use-flomni';
import FooterReviewButton from '@/components/Footer/FooterReviewButton.vue';
import ClientOnly from '@/components/ClientOnly.vue';
import FooterLocation from './icons/footer-location.svg';

defineProps<{
  feedback: FooterFeedback;
}>();

const store = useStore();
const displayChat = computed(() => store.getters['flomni/displayChat']);

const { phoneNumber, phoneNumberFormatted, openCityModal, globalCity } = useSelectedCityData();

const { isRuSite, currentLocaleISO } = useSiteInfo();

const { getSettingsGroup } = useSettings();

const settings = getSettingsGroup('all');

const helpLink = computed(() => {
  return `/${currentLocaleISO.value}/faq/`;
});

onMounted(() => {
  if (!isRuSite.value) {
    return;
  }

  const script = document.querySelector('script[type="application/ld+json"]#organization');

  if (!script) {
    return;
  }

  try {
    const orgData = JSON.parse(script?.textContent ?? '');

    const orgDataNumber = orgData.contactPoint[0].telephone;

    const noSpacingPhoneNumber = phoneNumber.value.replace(/\s/g, '');

    if (orgDataNumber !== noSpacingPhoneNumber) {
      orgData.contactPoint[0].telephone = noSpacingPhoneNumber;

      script.textContent = JSON.stringify(orgData);
    }
  } catch (e) {
    cdekConsoleError(e, { dontSendSentry: true });
  }
});

const showFeedback = computed(() => {
  return displayChat.value || settings.displayHelpCustomer;
});

const { showFlomniComponent } = useFlomni();
</script>

<style lang="scss" scoped>
.footer-feedback {
  min-width: 250px;

  &__title {
    @include headline-6;

    margin-bottom: 12px;
  }

  &__link {
    @include body-2;

    color: $Peak_80;
    text-decoration: none;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__phone {
    padding-bottom: 4px;
  }

  &__messenger {
    @include headline-5;

    padding: 16px 0;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    color: #fff;
    cursor: pointer;

    a {
      text-decoration: none !important;
    }

    &-icon {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
  }

  &__city {
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 14px;
  }
}
</style>
