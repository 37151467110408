<template>
  <div v-if="enabledLK" class="mobile-user">
    <div v-if="cabinet" id="header-cabinet-place-mobile" />

    <template v-if="isLoggedInCabinet">
      <a :href="`/${locale}/cabinet/`" class="mobile-user__item" @click.stop="closeMenu">
        <span class="mobile-user__item-icon"><icon-user /></span>
        <span class="mobile-user__item-text">{{ userName }}</span>
      </a>
      <button class="mobile-user__item" @click="logOut">
        <span class="mobile-user__item-icon"><icon-logout /></span>
        <span class="mobile-user__item-text">{{ $t('lk.menu.logOut') }}</span>
      </button>
    </template>
    <template v-else-if="enabledLoginBtn">
      <div class="mobile-user__item">
        <span v-if="isLoading" class="mobile-user__item-loader">
          <base-loader :old="false" />
        </span>
        <cdek-button v-test-id="'auth-login-btn'" small @click="login">
          {{ $t('lk.menu.logIn') }}</cdek-button
        >
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseLoader from '@/components/BaseComponents/BaseLoader.vue';
import useUsername from '@/components/Login/use-username';
import useLogin from '@/components/Login/use-login';
import { useMenuStore } from '@/store/pinia/menu/menu';
import { storeToRefs } from 'pinia';
import { CdekButton } from '@cdek-ui-kit/vue';
import useLogout from '@/composables/use-logout';
import { onMounted, onUnmounted } from 'vue';
import useModal from '@/composables/use-modal';
import SelectLoginTypeModal from '@/components/Modal/SelectLoginTypeModal/index.vue';
import useSiteInfo from '@/composables/use-site-info';
import IconUser from './icons/mobile-drawer-user.svg';
import IconLogout from './icons/mobile-drawer-logout.svg';

export default {
  name: 'MobileDrawerUser',
  components: {
    CdekButton,
    BaseLoader,
    IconUser,
    IconLogout,
  },
  props: {
    cabinet: {
      type: Boolean,
    },
  },
  setup() {
    const userName = useUsername();
    const { isLoading, onLogin } = useLogin();
    const { logOut } = useLogout();
    const menuStore = useMenuStore();

    const { isRuSite } = useSiteInfo();

    const modal = useModal();

    const { enabledLoginBtn } = storeToRefs(menuStore);

    onMounted(() => {
      // Этот костыль обязателен для кабинета
      menuStore.setUserMenuOpen(true);
    });

    onUnmounted(() => {
      // Этот костыль обязателен для кабинета
      menuStore.setUserMenuOpen(false);
    });

    const closeMenu = () => {
      menuStore.closeMobileMenu();
    };

    const openSelectLoginTypeModal = () => {
      modal.show({
        component: SelectLoginTypeModal,
      });
    };

    const login = () => {
      closeMenu();
      if (isRuSite.value) {
        openSelectLoginTypeModal();
        return;
      }

      onLogin();
    };

    return {
      onLogin,
      isLoading,
      userName,
      enabledLoginBtn,
      menuStore,
      logOut,
      login,
      closeMenu,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedInCabinet: 'isLoggedInCabinet',
      enabledLK: 'auth/enabledLK',
      locale: 'currentLocaleISO',
    }),
  },
};
</script>

<style scoped lang="scss">
.mobile-user {
  margin-bottom: 12px;

  &__item {
    cursor: pointer;
    margin-top: 12px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    text-decoration: none;
    text-align: left;

    :deep(.cdek-button) {
      width: 100%;

      @include media-min-sm {
        max-width: 288px;
      }
    }

    &-loader,
    &-icon {
      width: 24px;
      height: 24px;
      flex-basis: 24px;
      margin-right: 16px;
    }

    &-text {
      flex: 1;
      width: 100%;
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      text-decoration: none;

      .mobile-user__item-text {
        color: rgba(21, 142, 58, 1);
      }
    }
  }
}
</style>
