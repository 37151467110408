<template>
  <div class="login">
    <div v-if="isSsoChecked && (isLoggedInCabinet || hasImpersonation)" class="logged-in-wrapper">
      <a v-if="hasImpersonation" href="#" @click.prevent="removeImpersonation(true)"> exit </a>
      <login-menu />
    </div>

    <login-button v-else :loading="!isSsoChecked" />
  </div>
</template>

<script>
import { mapActions, mapGetters, useStore } from 'vuex';
import { computed, defineAsyncComponent } from 'vue';
import LoginButton from './LoginButton.vue';

export default {
  name: 'Login',

  components: {
    LoginMenu: defineAsyncComponent(() => import('./LoginMenu.vue')),
    LoginButton,
  },

  setup() {
    const store = useStore();

    const isSsoChecked = computed(() => {
      return store.state.auth.isSsoChecked;
    });

    return {
      isSsoChecked,
    };
  },

  computed: {
    ...mapGetters({
      currentLocaleISO: 'currentLocaleISO',
      impersonation: 'auth/getImpersonation',
      isLoggedInCabinet: 'isLoggedInCabinet',
    }),

    hasImpersonation() {
      return this.impersonation && this.impersonation.hasOwnProperty('_t');
    },
  },

  methods: {
    ...mapActions({
      removeImpersonation: 'auth/removeImpersonation',
    }),
  },
};
</script>

<style scoped lang="scss">
.login {
  display: inline-flex;
  align-items: center;
  align-self: center;
  height: 100%;

  .logged-in-wrapper {
    display: flex;
    align-items: center;

    @include appear();
  }

  .cabinet-link {
    display: flex;
    align-items: center;

    .cabinet-icon {
      width: 18px;
      height: 18px;
      margin-right: 4px;
      background-repeat: no-repeat;
      background-position: center center;
    }

    a {
      text-decoration: none;
      color: #333;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
</style>
