import { mapActions, mapGetters, mapMutations } from 'vuex';
import type { ComponentOptionsMixin } from 'vue';
import { cdekConsoleError } from '@/utils/console-wrapper';
import { FOOTER_TYPE } from '@/middleware/mutation-types';
import sbjs from 'sourcebuster';
import { sendDataToADV } from '@/plugins/ADV-plugin';
import usePage from '@/composables/use-page';
import useSettings from '@/composables/use-settings';

const { getSettingsGroup } = useSettings();

const layoutMixin: ComponentOptionsMixin = {
  name: 'layout-mixin',
  computed: {
    ...mapGetters({
      websiteId: 'websiteId',
      currentLocale: 'currentLocale',
      consentToUseOfCookies: 'cookie-alert/consentToUseOfCookies',
      currentLocaleISO: 'currentLocaleISO',
      screen: 'resize/screen',
      currentPage: 'page/currentPage',
    }),
    selectedCity() {
      return this.$store.getters.city;
    },
    hasConsetToUseOfCookie() {
      return !this.consentToUseOfCookies;
    },
    headerSettings() {
      return {
        logo: this.siteLogo,
        logoMobile: this.siteLogoMobile,
        cabinet: false,
      };
    },
    settingsAll() {
      return getSettingsGroup('all');
    },
    siteLogo() {
      return getSettingsGroup('logo');
    },
    siteLogoMobile() {
      return getSettingsGroup('logo_mobile');
    },
  },

  methods: {
    ...mapMutations({
      updateYaMapsBalloonOpened: 'flomni/updateYaMapsBalloonOpened',
      setIsSite: 'setIsSite',
    }),
    ...mapActions({
      setWidth: 'resize/setWidth',
      setScreenByType: 'resize/setScreenByType',
      initCookie: 'cookie-alert/init',
      updateShowPageData: 'page/updateShowPageData',
    }),
    getWindowWidth() {
      const { clientWidth } = document.documentElement;
      this.setWidth({ clientWidth });
    },
    async updateFooterInfo(cityId?: string) {
      const variables = {
        locale: this.currentLocaleISO,
        cityId,
      };

      this.$store.dispatch(`footer/${FOOTER_TYPE.START_LOADING_FOOTER}`);

      try {
        const footer = await this.$restApi.websiteFooter(variables);
        this.$store.dispatch(`footer/${FOOTER_TYPE.SET_FOOTER}`, {
          footer,
        });
      } catch (e) {
        cdekConsoleError(e);
      } finally {
        this.$store.dispatch(`footer/${FOOTER_TYPE.STOP_LOADING_FOOTER}`);
      }
    },
  },
  watch: {
    selectedCity(city) {
      (this as any).updateFooterInfo(city && city.cdek_id);
    },
  },
  mounted() {
    const { isHomePage } = usePage();

    sendDataToADV(isHomePage.value ? 1 : 2);

    this.initCookie(this.$cookies.get('consent-to-use-of-cookies'));

    if (window.location.href.includes('cabinet')) {
      this.setIsSite(false);
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });

    if (!window.sbjs) {
      window.sbjs = sbjs;

      window.sbjs.init({
        lifetime: 6,
        session_length: 30,
        domain: {
          host: document.domain,
          isolate: false,
        },
        timezone_offset: 0,
        // Set `utm_source` & `utm_medium` values for `typein` traffic
        // Defaults are `(direct)` & `(none)`
        typein_attributes: {
          source: '(direct)',
          medium: '(none)',
        },
      });
    }
  },
  created() {
    // сбрасываем флаг открыт ли балун на карте, из vuex-persist может подтягиваться не актуальное значение
    this.updateYaMapsBalloonOpened(false);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
};

export default layoutMixin;
