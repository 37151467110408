<template>
  <div v-if="enabledLoginBtn" class="login-button">
    <cdek-button
      v-test-id="'auth-login-btn'"
      v-click-outside="{
        handler: () => {
          opened = false;
        },
      }"
      small
      :loading="isLoading || loading"
      @click="onClickLoginButton"
    >
      {{ $t('logIn') }}
    </cdek-button>

    <cdek-dropdown-box v-if="isRuSite && opened" class="login-button__dropdown">
      <cdek-dropdown-item
        v-for="item in items"
        :key="item.value"
        :value="item"
        @click="onClickDropdown(item.value)"
      >
        {{ item.title }}
      </cdek-dropdown-item>
    </cdek-dropdown-box>
  </div>
</template>

<script lang="ts" setup>
import { CdekButton, CdekDropdownBox, CdekDropdownItem } from '@cdek-ui-kit/vue';
import { useMenuStore } from '@/store/pinia/menu/menu';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import useSiteInfo from '@/composables/use-site-info';
import { useI18n } from 'vue-i18n';
import { LoginTypes } from '@/components/Login/consts';
import useLogin from './use-login';

defineProps<{ loading?: boolean }>();

const opened = ref(false);

const { isRuSite } = useSiteInfo();

const { t } = useI18n();
const menuStore = useMenuStore();
const { enabledLoginBtn } = storeToRefs(menuStore);
const { isLoading, onLogin, openModalByType } = useLogin();

// TODO: вынести в хук, потому что уже 5000 таких методов
const toggleOpened = () => {
  opened.value = !opened.value;
};
const onClickDropdown = (value: number) => {
  toggleOpened();
  openModalByType(value);
};

const onClickLoginButton = () => {
  if (isRuSite.value) {
    toggleOpened();
    return;
  }

  onLogin();
};

const items = [
  { title: t('loginTypeSelect.individual'), value: LoginTypes.individual },
  { title: t('loginTypeSelect.legal'), value: LoginTypes.legal },
];
</script>

<style lang="scss" scoped>
.login-button {
  min-width: 89px;
  width: max-content;
  position: relative;

  :deep(.cdek-dropdown-box) {
    position: absolute;
    right: 0;
    top: 38px;
    width: 172px;
  }

  :deep(.cdek-button) {
    padding: 6px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }
}
</style>
